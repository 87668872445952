import revive_payload_client_7LeeE4n3Fl from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_S1pZfdXtaK from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yz6MrF0l1o from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_q3vIVfkgjI from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.0_@types+node@22.13.9_jiti@2.4.2_terser_6351b10f84aad9cbe255f67fad970795/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_I3d9kDyO5M from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ooBrugwmnu from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_z90CaV4wrK from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CIiUiApxuu from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mpTpts533Z from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/.nuxt/components.plugin.mjs";
import prefetch_client_WDkwva9sId from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_GfrWHDKzR8 from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.8.2___magica_899d836a579d888ae7d08178f2950a2e/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import fontawesome_QPROSg7y7G from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@vesp+nuxt-fontawesome@1.2.1_magicast@0.3.5/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import nuxt_plugin_Z8aREu0wjA from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_2gSJ9MupqG from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.9_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_yRrGzeq9XJ from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.9_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_xPxXZiO4uc from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.8.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import base64_2hii42QBJd from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import plugin_bpnFzZp0x8 from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8_56bc314c1b314c94013faae7bd392089/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_7LeeE4n3Fl,
  unhead_S1pZfdXtaK,
  router_yz6MrF0l1o,
  _0_siteConfig_q3vIVfkgjI,
  payload_client_I3d9kDyO5M,
  navigation_repaint_client_ooBrugwmnu,
  check_outdated_build_client_z90CaV4wrK,
  chunk_reload_client_CIiUiApxuu,
  plugin_vue3_mpTpts533Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WDkwva9sId,
  plugin_OHU0vggoM9,
  plugin_GfrWHDKzR8,
  fontawesome_QPROSg7y7G,
  nuxt_plugin_Z8aREu0wjA,
  switch_locale_path_ssr_2gSJ9MupqG,
  i18n_yRrGzeq9XJ,
  plugin_xPxXZiO4uc,
  base64_2hii42QBJd,
  plugin_bpnFzZp0x8
]