
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93unYevDR5y3Meta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as airport_45hotels_45with_45parkingqtHqqiD9zGMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as airport_45hotelsfNp9PjwjalMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels.vue?macro=true";
import { default as airport_45parkingAKyyUrEDCCMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-parking.vue?macro=true";
import { default as port_45hotels_45with_45parkingT8ilnfPs1MMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as port_45parkingUCqZmO4EX4Meta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-parking.vue?macro=true";
import { default as healthC8vmVE7D47Meta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/health.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93v8h5dZfBdVMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93itQDT4JJB0Meta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93tLwEu0XqhQMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stub2lZYKHDI4xMeta } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub2lZYKHDI4x } from "/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.2.4_eslint@8.57.1_ioredis@5_92d416bc333b6b87df72feac06fc90c0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-hotels___en",
    path: "/:IATA()/airport-hotels",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-parking.vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/health.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src720937022/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: component_45stub2lZYKHDI4xMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub2lZYKHDI4x
  }
]